import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import FilterListIcon from "@mui/icons-material/FilterList";
import clsx from "clsx";

import ArrowAsLid from "components/uikit/Icons/ArrowAsLid";

import { IconType } from "./interface";

export const getIcon = ({
  type,
  className,
  fill,
}: {
  type: IconType;
  className?: string;
  fill?: string;
}) => {
  switch (type) {
    case "filter":
      return <FilterListIcon className={className} />;

    case "show":
      return <ArrowAsLid fill={fill} className={clsx("rotate-90 transform", className)} />;

    case "copy":
      return <ContentCopyIcon fill={fill} className={className} />;

    default:
      return <ArrowAsLid fill={fill} className={className} />;
  }
};
