import tw, { styled } from "twin.macro";

import { IStyled } from "./interface";

export const SpinnerStyled = styled.div<IStyled>`
  ${tw`relative inline-block`}
  ${({ size }) => (size === "L" ? tw`h-84 w-84 lg:h-44 lg:w-44` : tw`h-44 w-44`)};
`;

export const SpinnerBorderStyled = styled.div<IStyled>`
  @keyframes loader_spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  ${tw`absolute rounded-full border-4 border-solid border-t-violet-100 border-r-transparent border-b-transparent border-l-transparent`};
  ${({ size }) => (size === "L" ? tw`h-84 w-84 lg:h-44 lg:w-44` : tw`h-44 w-44`)};
  animation: loader_spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;

  &:nth-of-type(1) {
    ${tw`border-solid border-t-violet-600 border-r-transparent border-b-transparent border-l-transparent`};
    ${({ size }) => (size === "L" ? tw`border-16 lg:border-12` : tw`border-12`)};
    animation-delay: -0.45s;
  }

  &:nth-of-type(2) {
    ${tw`border-solid border-t-violet-300 border-r-transparent border-b-transparent border-l-transparent`};
    ${({ size }) => (size === "L" ? tw`border-12 lg:border-8` : tw`border-8`)};
    animation-delay: -0.3s;
  }

  &:nth-of-type(3) {
    ${tw`border-solid border-t-violet-200 border-r-transparent border-b-transparent border-l-transparent`};
    ${({ size }) => (size === "L" ? tw`border-8 lg:border-4` : tw`border-4`)};
    animation-delay: -0.15s;
  }
`;
