import BASE from "./base";

// import MENU from "./menu";

const routes = {
  base: BASE,
  // menu: MENU,
};

export default routes;
