import { useEffect, useRef } from "react";

function useFirstUpdate() {
  const firstUpdate = useRef(true);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
  });

  return firstUpdate.current;
}

export default useFirstUpdate;
