import { lazy } from "react";
import type { RouteObject } from "react-router-dom";

import Layout from "frame/Layout";

/** Главная страница */
const Main = lazy(() => import("pages/Main"));
const EKP = lazy(() => import("pages/EKP"));
const Cartographic = lazy(() => import("pages/Cartographic"));
const CheckingRank = lazy(() => import("pages/CheckingRank"));
const NSI = lazy(() => import("pages/NSI"));
const AthleticFacilities = lazy(() => import("pages/AthleticFacilities"));
const Organizations = lazy(() => import("pages/Organizations"));
const SportTypes = lazy(() => import("pages/SportTypes"));
const SportsFederations = lazy(() => import("pages/SportsFederations"));
const Monitoring = lazy(() => import("pages/Monitoring"));

const BASE: RouteObject[] = [
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Main />,
      },
      {
        path: "ekp",
        element: <EKP />,
      },
      {
        path: "cartographic",
        element: <Cartographic />,
      },
      {
        path: "rank-verification",
        element: <CheckingRank />,
      },
      {
        path: "nsi",
        element: <NSI />,
      },
      {
        path: "athletic-facilities",
        element: <AthleticFacilities />,
      },
      {
        path: "organizations",
        element: <Organizations />,
      },
      {
        path: "sport-types",
        element: <SportTypes />,
      },
      {
        path: "sports-federations",
        element: <SportsFederations />,
      },
      {
        path: "monitoring",
        element: <Monitoring />,
      },
    ],
  },
];

export default BASE;
