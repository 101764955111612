export const MENU_ITEMS = [
  {
    id: 1,
    title: "Главная",
    to: "/",
  },
  {
    id: 7,
    title: "Минспорт России",
    href: "http://www.minsport.gov.ru/",
  },
  {
    id: 8,
    title: "«Спорт – норма жизни»",
    href: "https://xn--80aapampemcchfmo7a3c9ehj.xn--p1ai/mediaProjects/dvizhenie-k-luchshemu",
  },
  {
    id: 9,
    title: "ВФСК ГТО",
    href: "https://user.gto.ru/user/register",
  },
  {
    id: 4,
    title: "Единый календарный план",
    to: "/ekp",
  },
  {
    id: 2,
    title: "Объекты спорта",
    to: "/cartographic",
  },
  {
    id: 6,
    title: "Организации спортивной подготовки",
    to: "/organizations",
  },
  {
    id: 11,
    title: "Всероссийский реестр видов спорта",
    to: "/sport-types",
  },
  {
    id: 12,
    title: "Спортивные федерации",
    to: "/sports-federations",
  },

  // {
  //   id: 3,
  //   title: "ГТО",
  //   href: "https://user.gto.ru/user/register",
  // },

  // {
  //   id: 5,
  //   title: "Налоговый вычет",
  //   href: "http://www.minsport.gov.ru/activities/o-nalogovom-vychete-za-zanyatiya-sportom/http://www.minsport.gov.ru/activities/o-nalogovom-vychete-za-zanyatiya-sportom/",
  // },
];
