import dayjs from "dayjs";
import { ErrorBoundary } from "react-error-boundary";
import { useRoutes } from "react-router-dom";

import Error from "pages/Error";
import TechnicalWorks from "pages/TechnicalWorks";

import routes from "routes";

import { checkChunkLoadError, refreshApplication } from "./utils";

require("dayjs/locale/ru");
dayjs.locale("ru");

function Frame() {
  const { base } = routes;
  let element = useRoutes(base);

  const logError = (error: Error) => {
    if (checkChunkLoadError(error)) {
      refreshApplication();
    }
  };

  /** Заглушка для технических работ */
  // if (process.env.REACT_APP_TECHNICAL_WORKS === "true") return <TechnicalWorks />;

  return (
    <ErrorBoundary FallbackComponent={(fallback) => <Error {...fallback} />} onError={logError}>
      {element}
    </ErrorBoundary>
  );
}

export default Frame;
