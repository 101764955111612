import clsx from "clsx";
import { useMemo } from "react";

import { KindButtonType } from "./interface";
import { SizeType } from "types/types";

export function useClasses(props: {
  size: SizeType;
  kind: KindButtonType;
  className?: string;
  disabled?: boolean;
  icon?: boolean;
}) {
  const { size, kind, className, disabled, icon } = props;

  const buttonClassName = useMemo(
    () =>
      clsx(
        "relative z-1 -ml-4 inline-flex items-center rounded-[100px] transition-all",
        {
          "cursor-default pointer-events-none hover:bg-transparent": disabled,
          "p1 min-h-48 pt-7 pb-6 lg:h-44 lg:max-h-44 lg:min-h-44 lg:text-xs": size === "M",
          "pr-14 pl-20": size === "M" && icon,
          "px-20": size === "M" && !icon,
          "min-h-72 px-32 pt-7 pb-6 text-md": size === "L",

          "border-2 border-solid border-violet-600 text-violet-700 hover:bg-violet-200 focus:bg-violet-200 active:bg-violet-200 disabled:text-gray-400 disabled:border-gray-400 disabled:hover:bg-transparent":
            kind === "ghost",
          "bg-white text-violet-800 shadow-button hover:bg-violet-200 focus:bg-violet-200 active:bg-violet-200 disabled:text-gray-400 disabled:border-gray-400 disabled:hover:bg-transparent":
            kind === "normal",
          "border-2 border-solid border-violet-700 text-violet-700 hover:bg-violet-100 focus:border-violet-300 focus:outline-violet-300 active:border-violet-600 disabled:text-gray-400 disabled:border-gray-400 disabled:hover:bg-transparent":
            kind === "light",
          "text-gray-400 border-gray-400": ["ghost", "light", "normal"].includes(kind) && disabled,

          "border-2 border-solid border-white text-white hover:bg-violet-600 focus:outline-violet-300 focus:border-[#173DCA] active:bg-violet-700 disabled:text-violet-900 disabled:border-violet-900 disabled:hover:bg-transparent":
            kind === "dark",
          "text-violet-900 border-violet-900": kind === "dark" && disabled,
        },
        className,
      ),
    [disabled, size, icon, kind, className],
  );

  const iconClassName = "ml-8";

  const iconFill = useMemo(
    () =>
      clsx({
        "text-violet-700": ["ghost", "light"].includes(kind) && !disabled,
        "text-violet-800": kind === "normal",
        "text-gray-400": ["ghost", "light", "normal"].includes(kind) && disabled,
        "text-white": kind === "dark" && !disabled,
        "text-violet-900": kind === "dark" && disabled,
      }),
    [kind, disabled],
  );

  return [buttonClassName, iconClassName, iconFill];
}
