import { useEffect, useState } from "react";

function useScroll() {
  const [scroll, setScroll] = useState<number>(0);

  useEffect(() => {
    function updateSize() {
      setScroll(window.scrollY);
    }

    window.addEventListener("scroll", updateSize);

    updateSize();

    return () => window.removeEventListener("scroll", updateSize);
  }, []);

  return scroll;
}

export default useScroll;
