import { Suspense, useRef } from "react";
import { Outlet } from "react-router-dom";

import PageFooter from "components/layout/PageFooter";
import PageHeader from "components/layout/PageHeader";
import PageMain from "components/layout/PageMain";
import Spinner from "components/uikit/Spinner";

function Layout() {
  const headerRef = useRef<HTMLElement>(null);
  return (
    <>
      <PageMain headerRef={headerRef}>
        <PageHeader headerRef={headerRef} />
        <Suspense fallback={<Spinner className="flex h-screen items-center justify-center" />}>
          <Outlet />
        </Suspense>
      </PageMain>
      <Suspense fallback="">
        <PageFooter />
      </Suspense>
    </>
  );
}

export default Layout;
