import { Link } from "react-router-dom";

import { getIcon } from "./helpers";
import { useClasses } from "./useClasses";

import IProps from "./interface";

function Button(props: IProps) {
  const {
    name,
    size = "M",
    icon = false,
    icon_type = "arrow",
    kind = "ghost",
    path,
    href,
    className,
    disabled,
    ...rest
  } = props;

  const [buttonClassName, iconClassName, fill] = useClasses({
    size,
    kind,
    className,
    disabled,
    icon,
  });

  if (path) {
    return (
      <Link tabIndex={disabled ? -1 : 0} className={buttonClassName} to={!disabled ? path : ""}>
        {name}
        {icon && getIcon({ type: icon_type, className: iconClassName, fill })}
      </Link>
    );
  }

  if (href) {
    return (
      <a
        className={buttonClassName}
        href={!disabled ? href : undefined}
        tabIndex={disabled ? -1 : 0}
        target="_blank"
      >
        {name}
        {icon && getIcon({ type: icon_type, className: iconClassName, fill })}
      </a>
    );
  }

  return (
    <button className={buttonClassName} disabled={disabled} {...rest}>
      {name}
      {icon && getIcon({ type: icon_type, className: iconClassName, fill })}
    </button>
  );
}

export default Button;
