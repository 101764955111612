import { useEffect, useState } from "react";

function useKeyPress(targetKey: string): boolean {
  // Состояние для отслеживания того, нажата ли клавиша
  const [keyPressed, setKeyPressed] = useState(false);
  // Если нажатая клавиша является нашей целевой клавишей, то установите значение true
  const downHandler = ({ key }: { key: string }): void => {
    if (key === targetKey) setKeyPressed(true);
  };
  // Если освобожденный ключ является нашим целевым ключом, то установите значение false
  const upHandler = ({ key }: { key: string }): void => {
    if (key === targetKey) setKeyPressed(false);
  };
  // Добавить прослушиватели событий
  useEffect(() => {
    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);
    // Удалить прослушиватели событий при очистке
    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  }, []); // Пустой массив гарантирует, что эффект будет выполняться только при монтировании и размонтировании
  return keyPressed;
}

export default useKeyPress;
