import CloseIcon from "@mui/icons-material/Close";
import clsx from "clsx";
import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";

import { BurgerMenuRoot, BurgerMenuStyled } from "./styles";

import useAnimation from "./useAnimation";
import useKeyPress from "hooks/useKeyPress";

import { IProps } from "./interface";

const burgerMenuRoot: any = document.getElementById("burger-menu-root");

function BurgerMenu({ onClose, isOpen, children }: IProps) {
  const burgerRef = useRef<HTMLDivElement>(null);
  const rootRef = useRef<HTMLDivElement>(null);

  const escPress = useKeyPress("Escape");
  const { showBackground } = useAnimation(isOpen);

  let touchStartX = 0;
  let touchEndX = 0;

  function checkDirection(event: Event) {
    if (isOpen && burgerRef.current && rootRef?.current?.contains(event.target as Node)) {
      if (touchEndX > touchStartX) {
        onClose();
      }
    }
  }

  useEffect(() => {
    if (escPress && isOpen) onClose();
  }, [escPress, isOpen]);

  useEffect(() => {
    if (isOpen) {
      rootRef?.current?.addEventListener("touchstart", (event) => {
        touchStartX = event.changedTouches[0].screenX;
      });

      rootRef?.current?.addEventListener("touchend", (event) => {
        touchEndX = event.changedTouches[0].screenX;
        checkDirection(event);
      });
    }
  }, [isOpen]);

  useEffect(() => {
    return () => {
      rootRef?.current?.removeEventListener("touchstart", checkDirection);
      rootRef?.current?.removeEventListener("touchend", checkDirection);
    };
  }, [!isOpen]);

  return ReactDOM.createPortal(
    <React.StrictMode>
      <BurgerMenuRoot
        onClick={onClose}
        isOpen={isOpen}
        showBackground={showBackground}
        ref={rootRef}
      >
        <BurgerMenuStyled
          onClick={(e) => e.stopPropagation()}
          isOpen={isOpen}
          showBackground={showBackground}
          ref={burgerRef}
        >
          <>
            <CloseIcon
              onClick={onClose}
              className={clsx(
                "absolute right-[18px] top-24 cursor-pointer text-gray-900 !transition-transform !delay-300 !duration-[400ms] !ease-[cubic-bezier(0.25,0.36,0.63,0.96)]",
                {
                  "translate-x-0": isOpen && showBackground,
                  "translate-x-full": !isOpen && !showBackground,
                },
              )}
            />
            {children}
          </>
        </BurgerMenuStyled>
      </BurgerMenuRoot>
    </React.StrictMode>,
    burgerMenuRoot,
  );
}

export default BurgerMenu;
