import { useEffect, useRef } from "react";

//Данный хук позволяет определить, был ли клик вне отслеживаемого элемента
function useOnClickOutsideRef(callback: () => void, state: boolean, initialValue: any = null) {
  const handler = (event: MouseEvent) => {
    if (state && !ref.current?.contains(event.target)) {
      callback();
    }
  };
  const ref = useRef(initialValue);
  useEffect(() => {
    window.addEventListener("click", handler);
    return () => window.removeEventListener("click", handler);
  }, [callback]);
  return ref;
}

export default useOnClickOutsideRef;
