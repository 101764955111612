import { useMemo } from "react";

import useWindowSize from "hooks/useWindowSize";

enum BREAKPOINT {
  md = 768,
  lg = 1024,
}

function useBreakpoints() {
  const [width] = useWindowSize();

  const { isDesktop, isTablet, isMobile } = useMemo(() => {
    if (width === 0)
      return {
        isDesktop: false,
        isTablet: false,
        isMobile: false,
      };

    return {
      isDesktop: width >= BREAKPOINT.lg,
      isTablet: width < BREAKPOINT.lg && width >= BREAKPOINT.md,
      isMobile: width < BREAKPOINT.md,
    };
  }, [width]);

  return { isDesktop, isTablet, isMobile };
}

export default useBreakpoints;
