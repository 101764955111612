// const DEFAULT_URL = "https://fcis-back.apps.ocp.dev.minsport.tech";
// export const API_HOST = process.env.REACT_APP_API_URL || DEFAULT_URL;
import { useEffect, useState } from "react";

import { API_HOST } from "lib/urls";

function useFetch(path: string, dependence?: any, isFullPath?: boolean) {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState<boolean>(false);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [data, setData] = useState<Array<any>>([]);

  useEffect(() => {
    if (isLoaded) setIsLoaded(false);

    // fetch(`${API_HOST}/registers/site/${path}`)
    fetch(
      `${API_HOST}/${process.env.REACT_APP_PREFIX_BACK_URL}/api/${
        isFullPath ? path : `nodes?full_path=registers/${path}`
      }`,
    )
      .then((res) => res.json())
      .then(
        (result) => {
          let resultData = result.data;

          if (result.data?.length > 0) {
            resultData = result.data?.map((it: any) => it?.report);
          }

          setSuccess(result.success);
          setIsLoaded(true);
          setData(resultData);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        },
      );
  }, [dependence, isFullPath]);

  return { data, isLoaded, error, success };
}

export default useFetch;
