// const DEFAULT_URL = "https://fcis-back.apps.ocp.dev.minsport.tech";
// export const API_HOST = process.env.REACT_APP_API_URL || DEFAULT_URL;
import { useEffect, useMemo, useState } from "react";

import { DEFAULT_PER_PAGE } from "shared/constants";

import { API_HOST } from "lib/urls";

function useFetchRoster(
  path: string,
  options?: { dependence?: any; page?: number; per_page?: number; query?: Record<string, string> },
) {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState<boolean>(false);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [data, setData] = useState<any>([]);
  const [meta, setMeta] = useState<any>();

  const queryParams = useMemo(
    () =>
      options?.query
        ? Object.keys(options.query)
            ?.map((key) => `${key}=${options.query?.[key]}`)
            .join("&")
        : "",
    [options?.query],
  );

  function getData(
    isConcatData: boolean,
    page: number = options?.page ?? 1,
    perPage: number = options?.per_page ?? DEFAULT_PER_PAGE,
  ) {
    setIsLoaded(false);
    setError(null);

    fetch(
      `${API_HOST}/${path}?page=${page}&per_page=${perPage}${queryParams ? "&" + queryParams : ""}`,
    )
      .then((res) => res.json())
      .then(
        (result) => {
          let resultData = result?.data;
          let meta = { total_count: result?.total_count, ...result?.meta };

          // if (result.data?.length > 0) {
          //   resultData = result.data?.map((it: any) => it?.report);
          // }

          if (resultData?.error) {
            setError(error);
            setData([]);
          }

          setSuccess(result.success);
          setIsLoaded(true);
          if (!resultData?.error) {
            setData((prev: any[]) => (isConcatData ? [...prev, ...resultData] : resultData));
            setMeta(meta);
            setError(null);
          }
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        },
      );
  }

  useEffect(() => {
    getData(false, 1, options?.per_page);
  }, [options?.dependence]);

  function getPerPageData(page: number, perPage: number) {
    getData(true, page, perPage);
  }

  return { data, meta, isLoaded, error, success, getPerPageData };
}

export default useFetchRoster;
