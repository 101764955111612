import clsx from "clsx";

// import { useLocation } from "react-router-dom";

// import { useScroll, useWindowSize } from "hooks";

function PageFooter() {
  let today = new Date();
  let year = today.getFullYear();

  // const scroll = useScroll();
  // const size = useWindowSize();
  // const location = useLocation();

  return (
    <footer
      className={clsx(
        "relative z-20 flex h-64 max-h-64 min-h-64 items-center justify-center bg-dark_blue",
        {
          // "sticky bottom-0": location?.pathname?.includes("nsi") && scroll > 0 && size[1] >= 750,
        },
      )}
    >
      <p className="text-xs text-white opacity-80 md:text-2xs">
        © {year} Динамика развития спорта в РФ
      </p>
    </footer>
  );
}

export default PageFooter;
