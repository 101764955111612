import clsx from "clsx";

function ArrowAsLid(props: { fill?: string; className?: string }) {
  const { fill = "text-violet", className } = props;
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" className={clsx(className, fill)}>
      <path
        d="M10.0001 6L8.59009 7.41L13.1701 12L8.59009 16.59L10.0001 18L16.0001 12L10.0001 6Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default ArrowAsLid;
