import { MutableRefObject, useEffect, useState } from "react";

import { BooleanType, IIntersectionObserverInit } from "types/types";

function useIsVisible(containerRef: MutableRefObject<any>) {
  const options: IIntersectionObserverInit = {
    root: null,
    rootMargin: "0px",
    threshold: 0,
    freezeOnceVisible: true,
  };

  const [isVisible, setIsVisible] = useState<BooleanType>(false);

  function callback(entries: IntersectionObserverEntry[]) {
    const [entry] = entries;

    setIsVisible(entry.isIntersecting);
  }

  useEffect(() => {
    const observer = new IntersectionObserver(callback, options);

    if (!isVisible) observer.observe(containerRef.current);

    return () => observer.disconnect();
  }, [containerRef, isVisible, options]);

  return [isVisible];
}

export default useIsVisible;
