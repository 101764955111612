import dayjs from "dayjs";
import { FallbackProps } from "react-error-boundary";

import Button from "components/uikit/Button";

function Error(props: FallbackProps) {
  const { error, resetErrorBoundary } = props;

  const errorText = `Локация ошибки:\n${JSON.stringify(window.location)}\n\nВремя ошибки:${dayjs().format("DD.MM.YYYY HH:mm:ss:SSSZ")}\n\n${String(error)}\n\nМесто ошибки:${error?.stack}\n\nБраузер:\n${JSON.stringify(window.navigator.userAgent)}`;

  return (
    <div className="m-auto max-w-[1120px]">
      <section className="flex flex-col items-center justify-center pt-[152px]">
        <h2 className="mb-12 text-center">Произошла ошибка на стороне клиента</h2>
        <p className="mb-32 text-center text-gray-500">
          Пожалуйста, напишите в техподдержку и передайте им эту информацию:
        </p>
        <textarea
          readOnly
          value={errorText}
          className="p3 mb-32 w-full max-w-[776px] rounded-4 border-1 border-solid border-gray-200 p-4"
          rows={12}
        />
        <div className="flex gap-16">
          <Button
            name="Скопировать текст ошибки"
            size="M"
            onClick={() => window.navigator.clipboard.writeText(errorText)}
            icon
            icon_type="copy"
          />
          <Button
            className="ml-16"
            name="Вернуться на главную страницу"
            size="M"
            path="/"
            onClick={resetErrorBoundary}
          />
        </div>
      </section>
    </div>
  );
}

export default Error;
