import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import clsx from "clsx";
import { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";

import { useBreakpoints, useScroll } from "hooks";

const heightFooter = 64;

function PageMain(props: any) {
  const { children, headerRef } = props;

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const scroll = useScroll();
  const { isDesktop } = useBreakpoints();

  function scrollToStart() {
    headerRef?.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  }

  const addedBottom = useMemo(() => {
    const screenHeight = window.innerHeight;

    const scrollHeight = Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight,
      document.body.clientHeight,
      document.documentElement.clientHeight,
    );

    const delta = scrollHeight - window.pageYOffset - screenHeight;

    if (scrollHeight - window.pageYOffset - screenHeight < heightFooter)
      return heightFooter - delta;
    return 0;
  }, [scroll]);

  const isBackdrop = useMemo(
    () =>
      ["ekp", "cartographic", "sport-types", "organizations", "monitoring"].some((path) =>
        location.pathname.includes(path),
      ),
    [location.pathname],
  );

  return (
    <main
      className={clsx("relative min-h-[calc(100vh-64px)] overflow-hidden", {
        "bg-violet_backdrop": isBackdrop,
        // "min-h-[calc(100vh-199px)] bg-violet_backdrop": location.pathname.includes("cartographic"),
        "relative overflow-hidden": !location.pathname.includes("nsi"),
      })}
    >
      {children}
      <button
        className="group fixed right-[94px] z-30 flex h-64 min-h-64 w-64 min-w-64 cursor-pointer items-center justify-center rounded-full bg-white shadow transition-all duration-300 disabled:cursor-auto disabled:bg-gray-200 disabled:shadow-unset lg:right-16 lg:h-40 lg:min-h-40 lg:w-40 lg:min-w-40"
        type="button"
        onClick={scrollToStart}
        style={{ bottom: `${addedBottom + (isDesktop ? 40 : 16)}px` }}
        disabled={scroll === 0}
      >
        <ArrowUpwardIcon
          fontSize="medium"
          className={clsx("transition-all duration-300", {
            "text-gray-400": scroll === 0,
            "text-violet-600 group-hover:text-violet-700": scroll !== 0,
          })}
        />
      </button>
    </main>
  );
}

export default PageMain;
