// const DEFAULT_URL = "https://fcis-back.apps.ocp.dev.minsport.tech";
// export const API_HOST = process.env.REACT_APP_API_URL || DEFAULT_URL;
import { useEffect, useState } from "react";

import { API_HOST } from "lib/urls";

function useFetchV2(path: string, dependence?: any) {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState<boolean>(false);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    setIsLoaded(false);
    setError(null);

    fetch(`${API_HOST}/${path}`)
      .then((res) => res.json())
      .then(
        (result) => {
          let resultData = result;

          // if (result.data?.length > 0) {
          //   resultData = result.data?.map((it: any) => it?.report);
          // }

          if (resultData?.error) {
            setError(error);
            setData([]);
          }

          setSuccess(result.success);
          setIsLoaded(true);
          if (!resultData?.error) {
            setData(resultData);
            setError(null);
          }
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        },
      );
  }, [dependence]);

  return { data, isLoaded, error, success };
}

export default useFetchV2;
