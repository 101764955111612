import tw, { styled } from "twin.macro";

import { IStyledProps } from "./interface";

export const BurgerMenuStyled = styled.aside<IStyledProps>`
  ${tw`bg-violet_backdrop pt-72 flex flex-col relative overflow-x-hidden
  transition-transform duration-[350ms] ease-[cubic-bezier(0.25,0.36,0.63,0.96)]
  lg:max-w-[311px] lg:min-w-[311px] md:max-w-[253px] md:min-w-[253px]
  `};

  ${({ isOpen, showBackground }) =>
    isOpen && showBackground ? tw`translate-x-0` : tw`translate-x-full`};
`;

export const BurgerMenuRoot = styled.div<IStyledProps>`
  ${tw`fixed top-0 right-0 bottom-0 left-0 flex justify-end bg-gray-900 bg-opacity-90 z-50
  transition-[opacity, visibility] duration-300 ease-[cubic-bezier(0.25,0.36,0.63,0.96)]
  `}

  ${({ showBackground }) => (showBackground ? tw`opacity-100 visible` : tw`opacity-0 invisible`)}}
`;
