import { useEffect, useState } from "react";

function useAnimation(isOpen: boolean) {
  const [showBackground, setShowBackground] = useState<boolean>(false);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => setShowBackground(true), 200);
    }

    if (!isOpen) {
      setTimeout(() => setShowBackground(false), 300);
    }
  }, [isOpen]);

  return { showBackground };
}

export default useAnimation;
