import clsx from "clsx";

import { SpinnerBorderStyled, SpinnerStyled } from "./styles";

import { IProps } from "./interface";

function Spinner(props: IProps) {
  const { className, size = "L" } = props;

  return (
    <div className={clsx("block text-center", className)}>
      <SpinnerStyled size={size}>
        <SpinnerBorderStyled size={size} />
        <SpinnerBorderStyled size={size} />
        <SpinnerBorderStyled size={size} />
        <SpinnerBorderStyled size={size} />
      </SpinnerStyled>
    </div>
  );
}

export default Spinner;
