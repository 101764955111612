import dayjs from "dayjs";

/**
 * Функция для обновления приложения если на сервере версия поменялась
 */
function refreshApplication() {
  const timestamp = sessionStorage.getItem("fkis.refresh_timestamp");
  const isRefresh = timestamp && dayjs(timestamp).add(3, "minutes").isAfter(Date());

  if (isRefresh) {
    // Таймаут нужен что бы не уходить в бесконечную перезагрузку
    setTimeout(() => sessionStorage.removeItem("fkis.refresh_timestamp"), 3 * 60 * 1000);
  } else {
    sessionStorage.setItem("fkis.refresh_timestamp", Date());
    window.location.reload();
  }
}

export default refreshApplication;
