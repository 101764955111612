import MenuIcon from "@mui/icons-material/Menu";
import clsx from "clsx";
import { useMemo, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";

import BurgerMenu from "components/uikit/BurgerMenu";

import logo_gerb_black from "assets/images/logo_gerb_black.svg";
import logo_gerb_white from "assets/images/logo_gerb_white.svg";

// import gost_logo_white from "assets/images/gost_logo_white.svg";
import { useBreakpoints, useScroll, useWindowSize } from "hooks";

import { MENU_ITEMS } from "./constants";

function PageHeader({ headerRef }: any) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { isDesktop, isMobile } = useBreakpoints();
  const scroll = useScroll();
  const size = useWindowSize();

  const location = useLocation();

  const isDarkHeader = useMemo(() => location?.pathname === "/", [location?.pathname]);

  function onClose() {
    setIsOpen(false);
  }

  return (
    <>
      {
        // location?.pathname?.includes("nsi") && size[1] >= 750 ? (
        // <div
        //   className={clsx("flex h-[104px] min-h-[104px] lg:h-[76px] lg:min-h-[76px]", {
        //     "bg-violet_backdrop":
        //       !location?.pathname?.includes("nsi") ||
        //       (location?.pathname?.includes("nsi") && !isDesktop),
        //   })}
        //   hidden={location?.pathname?.includes("nsi") && scroll > 0}
        // >
        //   <header
        //     ref={headerRef}
        //     className={clsx(
        //       "right-0 top-0 left-0 z-20 flex items-center justify-start transition-[height,colors] duration-300",
        //       {
        //         "bg-violet_backdrop":
        //           !location?.pathname?.includes("nsi") ||
        //           (location?.pathname?.includes("nsi") && !isDesktop),
        //         "h-64 min-h-64 bg-violet_backdrop shadow lg:h-64 lg:min-h-64":
        //           location?.pathname?.includes("nsi") && scroll > 0 && size[1] >= 750,
        //         "h-[104px] min-h-[104px] lg:h-[76px] lg:min-h-[76px]":
        //           !location?.pathname?.includes("nsi") ||
        //           (location?.pathname?.includes("nsi") && scroll === 0),
        //         fixed: location?.pathname?.includes("nsi") && size[1] >= 750,
        //         relative: !location?.pathname?.includes("nsi") || size[1] <= 750,
        //       },
        //     )}
        //   >
        //     <div className="container m-auto flex items-center justify-between">
        //       <div className="flex items-center">
        //         <img src={logo_gerb_black} className="mr-16 h-40 md:h-32" alt="Герб" />{" "}
        //         {!isMobile && (
        //           <>
        //             <a
        //               className="mr-16 flex items-center"
        //               href="http://www.minsport.gov.ru/"
        //               target="_blank"
        //               rel="noreferrer"
        //             >
        //               <p className="p1 text-sm font-semibold text-gray-900 lg:hidden">
        //                 Минспорт России
        //               </p>
        //             </a>
        //             <span className="mr-16 h-24 w-1 bg-gray-300 lg:hidden" />
        //           </>
        //         )}
        //         <Link to="/" className="flex items-center">
        //           <p className="p1 text-sm font-semibold text-gray-900 md:pt-4">
        //             ГИС «Спорт» (ФКиС)
        //           </p>
        //         </Link>
        //       </div>
        //       {!isDesktop && (
        //         <MenuIcon className="cursor-pointer" onClick={() => setIsOpen(!isOpen)} />
        //       )}
        //     </div>
        //   </header>
        // </div>
        // ) :
        <header
          ref={headerRef}
          className={clsx(
            "relative flex h-[104px] min-h-[104px] items-center justify-start lg:h-[76px] lg:min-h-[76px]",
            {
              "bg-violet_backdrop": !isDarkHeader,
              "overflow-hidden bg-violet-900 pt-[54px] lg:pt-0": isDarkHeader,
            },
          )}
        >
          <div className="container relative m-auto flex items-center justify-between">
            <div className="relative z-40 flex items-center">
              <a
                className="mr-16 flex items-center"
                href="http://www.minsport.gov.ru/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={isDarkHeader ? logo_gerb_white : logo_gerb_black}
                  className={"h-40 md:h-32"}
                  alt="Герб"
                />
                {isDesktop && (
                  <p
                    className={clsx("p1 ml-16 text-sm font-semibold", {
                      "text-gray-900": !isDarkHeader,
                      "text-white lg:hidden": isDarkHeader,
                    })}
                  >
                    Минспорт России
                  </p>
                )}
              </a>
              {!isMobile && (
                <span
                  className={clsx("mr-16 h-24 w-1 lg:hidden", {
                    "bg-gray-300": !isDarkHeader,
                    "bg-violet-100 opacity-30": isDarkHeader,
                  })}
                />
              )}
              <a href="https://sportrf.gov.ru" target="_self" className="flex items-center">
                <p
                  className={clsx("p1 mr-16 text-sm font-semibold md:pt-4", {
                    "text-gray-900": !isDarkHeader,
                    "text-white": isDarkHeader,
                  })}
                >
                  ФГИС «Спорт»
                </p>
              </a>

              {isDesktop && (
                <>
                  <span
                    className={clsx("mr-16 h-24 w-1 lg:hidden", {
                      "bg-gray-300": !isDarkHeader,
                      "bg-violet-100 opacity-30": isDarkHeader,
                    })}
                  />
                  <a
                    className="mr-16 flex items-center"
                    href="https://xn--80aapampemcchfmo7a3c9ehj.xn--p1ai/mediaProjects/dvizhenie-k-luchshemu"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <p
                      className={clsx("p1 text-sm font-semibold", {
                        "text-gray-900": !isDarkHeader,
                        "text-white lg:hidden": isDarkHeader,
                      })}
                    >
                      «Спорт – норма жизни»
                    </p>
                  </a>
                </>
              )}

              {isDesktop && (
                <>
                  <span
                    className={clsx("mr-16 h-24 w-1 lg:hidden", {
                      "bg-gray-300": !isDarkHeader,
                      "bg-violet-100 opacity-30": isDarkHeader,
                    })}
                  />
                  <a
                    className="mr-16 flex items-center"
                    href="https://user.gto.ru/user/register"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <p
                      className={clsx("p1 text-sm font-semibold", {
                        "text-gray-900": !isDarkHeader,
                        "text-white lg:hidden": isDarkHeader,
                      })}
                    >
                      ВФСК ГТО
                    </p>
                  </a>
                </>
              )}
            </div>
            {!isDesktop && (
              <MenuIcon
                className={clsx("cursor-pointer", {
                  "text-gray-900": !isDarkHeader,
                  "text-white": isDarkHeader,
                })}
                onClick={() => setIsOpen(!isOpen)}
              />
            )}
            {/* {isDarkHeader && isDesktop && (
              <img
                src={gost_logo_white}
                className="relative z-40 h-12 !opacity-100"
                alt="Логотип гостех"
              />
            )} */}
          </div>
        </header>
      }

      <BurgerMenu isOpen={isOpen} onClose={onClose}>
        <ul>
          {MENU_ITEMS.map((menuItem: any, index: number) => {
            if (menuItem?.href) {
              return (
                <li key={`menu-item_${menuItem?.id}`}>
                  <a href={menuItem?.href} onClick={onClose} target="_blank" rel="noreferrer">
                    <span
                      className={clsx(
                        `text-gray-1500 inline-block w-full border-l-3 border-t-1 border-y-gray-200 border-l-transparent py-16 pl-20 text-xs first-letter:uppercase hover:border-l-3 hover:text-violet-700 focus:border-l-3 focus:text-violet-700 active:border-l-3 active:text-violet-700`,
                        {
                          "border-b-1": MENU_ITEMS.length === index + 1,
                        },
                      )}
                    >
                      {menuItem?.title}
                    </span>
                  </a>
                </li>
              );
            }

            return (
              <li key={`menu-item_${menuItem?.id}`}>
                <NavLink
                  to={menuItem?.to}
                  onClick={onClose}
                  children={({ isActive }: { isActive: boolean }) => (
                    <span
                      className={clsx(
                        `text-gray-1500 inline-block w-full border-l-3 border-t-1 border-y-gray-200 py-16 pl-20 text-xs first-letter:uppercase hover:border-l-3 hover:text-violet-700 focus:border-l-3 focus:text-violet-700 active:border-l-3 active:text-violet-700`,
                        {
                          "border-b-1": MENU_ITEMS.length === index + 1,
                          "border-l-violet-600 text-violet-700": isActive,
                          "border-l-transparent": !isActive,
                        },
                      )}
                    >
                      {menuItem?.title}
                    </span>
                  )}
                />
              </li>
            );
          })}
        </ul>
      </BurgerMenu>
    </>
  );
}

export default PageHeader;
